import httpService from '@/services/httpService'
import { IAddress, IAppointment, IState } from './consultation.service'
import { useState } from 'react'
import { IPatient } from '@/interface/patient.interface'
import { IDataTransaction } from '@/services/self-patient.service'

export interface IDataAppointment {
  id: string
  createdAt: string
  uid: string
  status: string
  startAt: string
  finishAt: string
  durations: number
  slot: number
  canceledAt?: any
  visitType: string
  insuranceStatus?: any
  insuranceStatusCode?: any
  plans: IPlan[]
  provider?: IProvider
  diagnosticCenterLocation?: IDiagnosticCenterLocation
  type: string
  fee: string
}

interface IDiagnosticCenterLocation {
  id: string
  name: string
  address: IAddress
}

interface IProvider {
  id: string
  npiNo: string
  status: string
  user: IUser
  specialty: IState
}

interface IUser {
  firstName: string
  lastName: string
  gender: string
  avatar?: any
  status: string
  addresses: IAddress[]
}

interface IPlan {
  id: string
  title: string
  price: string
}

export interface IReserveAppointment {
  pivot: number
  id: number
  availabilityId: number
  availableAppointmentId: number
  date: string
  type: 'consultation' | 'scanner'
  planIds: number[]
  appointmentId?: number
  appointmentUID?: string
}

export interface RootObjectDataRef {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: any
  dayOfWeek: string
  startTime: string
  endTime: string
}

export interface IReserveResponse {
  status: string
  startAt: string
  finishAt: string
  durations: number
  slot: number
  visitType: string
  ref: RootObjectDataRef
  patient: IPatient
  provider?: IProvider
  diagnosticCenterLocation: IDiagnosticCenterLocation
  deletedAt?: any
  comments?: any
  meetingId?: any
  zoomMeeting?: any
  canceledAt?: any
  insuranceStatus?: any
  insuranceStatusCode?: any
  availityRef?: any
  id: number
  createdAt: string
  updatedAt: string
  uid: string
}

export type StripePaymentMethodTypes =
  | 'default'
  | 'card'
  | 'bank_debit'
  | 'bank_redirect'
  | 'bank_transfer'
  | 'buy_now_pay_later'
  | 'wallet'

export const useAppointmentService = () => {
  const [data, setData] = useState<IAppointment | null>(null)
  const [scannerId, setScannerId] = useState<number | null>(null)
  const [providerNpi, setProviderNpi] = useState<number | null>(null)
  const clear = () => {
    setData(null)
    setScannerId(null)
    setProviderNpi(null)
  }

  const getAppointment = async (): Promise<IDataAppointment[]> => {
    const res: {
      data: IDataAppointment[]
    } = await httpService.get('/v1/selfPatient/appointment?page=1&limit=10&type=active')
    return Promise.resolve(res.data)
  }

  const reserve = async (data: IReserveAppointment): Promise<IReserveResponse> => {
    const request = { ...data, planIds: data.planIds.toString() }
    const res: { data: IReserveResponse } = await httpService.post('/v1/selfPatient/appointment/reserve', request)
    return Promise.resolve(res.data)
  }

  const cancelReserved = async (id: number): Promise<any> => {
    const res = await httpService.put(`/v1/selfPatient/appointment/reserve/${id}/cancel`)
    return Promise.resolve(res)
  }

  const addAppointment = async (data: {
    uid: string
    hasInsurance: boolean
    paymentMethods: StripePaymentMethodTypes
    paymentMethodId?: string
  }): Promise<{ transaction: IDataTransaction; appointment: IAppointment }> => {
    const res: { data: { transaction: IDataTransaction; appointment: IAppointment } } = await httpService.post(
      '/v1/selfPatient/appointment',
      data,
    )
    return Promise.resolve(res.data)
  }

  return {
    data,
    scannerId,
    clear,
    setData,
    setScannerId,
    setProviderNpi,
    providerNpi,
    getAppointment,
    reserve,
    cancelReserved,
    addAppointment,
  }
}
