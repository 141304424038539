import React, { useContext } from 'react'

type paymentContextType = {
  hasInsurance: boolean
  setInsurance: (value: boolean) => void
  call: number
  setCall: (value: number) => void
  isLoading: boolean
  setLoading: (value: boolean) => void
}

const paymentContextDefaultValues: paymentContextType = {
  hasInsurance: false,
  setInsurance: (value: boolean) => undefined,
  call: 0,
  setCall: (value: number) => undefined,
  setLoading: (value: boolean) => undefined,
  isLoading: false,
}

export const PaymentContext = React.createContext<paymentContextType>(paymentContextDefaultValues)

export const PaymentContextProvider = ({ children, ...props }: any) => {
  return <PaymentContext.Provider {...props}>{children}</PaymentContext.Provider>
}

export const usePaymentContext = () => useContext(PaymentContext)
