import { useState } from 'react'

export const usePaymentService = () => {
  const [hasInsurance, setInsurance] = useState<boolean>(false)
  const [call, setCall] = useState<number>(0)
  const [isLoading, setLoading] = useState<boolean>(false)

  return {
    hasInsurance,
    setInsurance,
    setCall,
    call,
    isLoading,
    setLoading,
  }
}
