import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import React from 'react'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color']
    }
  }

  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface PaletteColor {
    darker?: string
  }

  interface SimplePaletteColorOptions {
    darker?: string
  }

  interface TypographyVariants {
    textLogo: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    textLogo?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textLogo: true
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#D8145F',
      dark: '#202732',
      500: '#636363',
      300: '#9C9C9C',
    },
    secondary: {
      main: '#7893BC',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#E7EDF7',
    },
    text: {
      primary: '#636363',
    },
    neutral: {
      main: '#64748B',
      50: '#E7EDF7',
      100: '#D0DBEF',
      200: '#B8C9E7',
      300: '#A0B7DF',
      400: '#89A5D6',
      500: '#7193CE',
      600: '#5981C6',
      700: '#426FBE',
      800: '#3961A7',
      900: '#31538F',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'Work Sans',
      'inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h6: {
      //styleName: H6 - 19px Bold Work Sans ;
      fontFamily: 'Work Sans',
      fontSize: '19px',
      fontWeight: '500',
      lineHeight: '23px',
      letterSpacing: '-0.04em',
      textAlign: 'center',
    },
    h5: {
      //styleName: H5 23px Work Sans;
      fontFamily: 'Work Sans',
      fontSize: '23px',
      fontWeight: '600',
      lineHeight: '28px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    h4: {
      //styleName: H4 28px Work Sans;
      fontFamily: 'Work Sans',
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '34px',
      letterSpacing: '-0.03em',
      textAlign: 'left',
      color: '#202732',
    },
    body1: {
      //styleName: Body 16px Regular Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    body2: {
      //styleName: Body 16px Bold Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    subtitle1: {
      //styleName: Small 14px Regular Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '14px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    textLogo: {
      //styleName: H5 23px Work Sans;
      fontFamily: 'Work Sans',
      fontSize: '23px',
      fontWeight: '600',
      lineHeight: '28px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#5981C6',
    },
  },
})

export default theme
