let devEnvironment: Partial<IEnvironment> = {}
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    devEnvironment = require('./environment.dev.ts').default
  } catch (e) {
    /**/
  }
}

const environment = {
  ENDPOINT: process.env.NEXT_PUBLIC_API_BASE_URL || '',
  LOGGING: process.env.NEXT_LOGGING_API_URL || '',
  GOOGLE_API_KEY: process.env.NEXT_APP_MAP_API || '',
}

export type IEnvironment = typeof environment
export default { ...environment, ...devEnvironment }
