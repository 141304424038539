import React, { useContext } from 'react'
import { IReserveAppointment } from '@/services/appointment.service'

type appointmentContextType = {
  scannerId: number | null
  providerNpi : number | null
  data: IReserveAppointment | null
  clear: () => undefined
  setData: (data: IReserveAppointment) => undefined
  setScannerId: (is: number) => undefined
  setProviderNpi : (val: number) => undefined
}

const appointmentContextDefaultValues: appointmentContextType = {
  clear: () => undefined,
  setData: (data: IReserveAppointment) => undefined,
  setScannerId: (id: number) => undefined,
  setProviderNpi : (val: number) => undefined,
  data: null,
  scannerId: null,
  providerNpi : null,
}

export const AppointmentContext = React.createContext<appointmentContextType>(appointmentContextDefaultValues)

export const AppointmentContextProvider = ({ children, ...props }: any) => {
  return <AppointmentContext.Provider {...props}>{children}</AppointmentContext.Provider>
}

export const useAppointmentContext = () => useContext(AppointmentContext)
